button {
    &.v-button-clear-styling {
        appearance: none;
        background-color: transparent;
        border: 0;
    }
}

//Base Button
.a-button {
    display: inline-block;
    position: relative;
    width: auto;
    min-height: 6rem;
    padding: 2.5rem 4rem;
    @include font(2.5rem, 2.5rem);
    font-weight: 500;
    font-family: 'Quicksand', sans-serif;
    cursor: pointer;
    border: $default-border-size solid;
    border-radius: 5rem;
    text-align: center;
    justify-content: center;
    @include tr();
 
    &:disabled,
    &[disabled] {
        pointer-events: none;
    }

    &:not(.v-button-full):not(.v-button-icon-right):not(.v-button-icon-left):not(.v-button-icon):not(.v-button-micro) {
        min-width: 24rem;
    }

    @include breakpoint($mq-sm-max) {
        width: 100%;
        min-width: 0;
    }
}

//Text Base Button
.a-text-button {
    display: inline-flex;
    width: auto;
    text-align: left;
    color: $color-text-functional;
    font-family: 'Quicksand', sans-serif;
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
    @include tr();
    &.v-active,
    &:hover {
        color: $color-greyscale-7;
        border-bottom-color: $color-greyscale-7;
        @include tr();
    }
}

//Primary Button
.v-button-primary {
    color: $color-greyscale-1;
    background-color: $color-primary-main;
    border-color: $color-primary-main;
    border-width: 3px;
    &:hover {
        color: $color-greyscale-1;
        background-color: $color-secondary-main;
        border-color: $color-primary-main;
        @include tr();
    }

    &:active {
        background-color: $color-primary-dark;
        border-color: $color-primary-dark;
        box-shadow: 0px 0px 10px $color-primary-main;
        box-shadow: none;
    }

    &:disabled,
    &[disabled] {
        color: $color-text-light;
        background-color: $color-greyscale-4;
        border-color: $color-greyscale-4;
    }

    &.v-button-loading {
        &:before {
            color: $color-greyscale-1;
        }
    }
}

//Secondary Button
.v-button-secondary {
    color: $color-text-dark;
    border-color: $color-text-dark;
    font-weight: 700;
    border-width: 0.25rem;
    &:hover {
        color: $color-text-dark;
        background-color: $color-greyscale-3;
        @include tr();
    }

    &:active {
        color: $color-text-light;
        border-color: $color-border-medium;
        box-shadow: 0px 0px 10px $color-primary-main;
    }

    &:disabled,
    &[disabled] {
        color: $color-text-light;
        background-color: $color-greyscale-4;
        border-color: $color-greyscale-4;
    } 

    &.v-button-loading {
        &:before {
            color: $color-text-light;
        }
    }
}

.v-button-micro {
    min-height: 4rem;
    margin-left: 2rem;
    padding: 1rem 2rem;
}

.v-button-loading {
    color: transparent;
    pointer-events: none;
    @include tr();
    @include loading-spinner;
}

.v-funding {
    color: $color-greyscale-1!important;
}

.v-button-icon {
    i {
        margin: 0;
    }
}

.v-button-icon-right {
    i {
        margin-left: 2rem;
        line-height: 2rem;
    }
}

.v-button-icon-left {
    i {
        margin-right: 2rem;
        line-height: 2rem;
    }
}

.v-button-full {
    width: 100%;
}

.v-button-center {
    display: table;
    width: auto;
    margin: 0 auto;
}

//Search button
.a-search-keyword-button {
    width: 100%;
    border: 0;
    background-color: $color-primary-main;
    color: $color-greyscale-1;
    padding: $space-s;
    text-align: left;
    @include tr();

    &:hover {
        @include tr();
        background-color: $color-primary-dark;
        color: $color-greyscale-1;
    }
}

p a span {
    color: $color-text-functional;
}