﻿.site-overlay-open {
    overflow: hidden;

    #a-site-overlay {
        pointer-events: visible;
        opacity: 0.9;
        z-index: 20;
        @include tr();
    }

    footer,
    main {
        filter: blur(0.375rem);
        @include tr();
    }
}

#a-site-overlay {
    position: fixed;
    top: 0;
    left: 0;
    pointer-events: none;
    background-color: $color-background-medium;
    height: 100%;
    width: 100%;
    opacity: 0;
    z-index: -1;
    @include tr();
}
