@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@keyframes fade {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fade-90 {
    from {
        opacity: 0;
    }

    to {
        opacity: 0.9;
    }
}