.o-cookie-warning {
    position: fixed;
    width: 100%;
    bottom: -100%;
    left: 0;
    background-color: $color-greyscale-6;
    z-index: 100;
    padding: $space-m 0;
    @include tr();
    color: $color-greyscale-1;
    p, span {
        color: $color-greyscale-1;
    }
    &.show-cookie-popup {
        bottom: 0;
        opacity: 1;
        @include tr();
    }
    a {
        color: $color-primary-main;
        text-decoration: underline;
    }
}
