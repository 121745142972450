.m-social-sharing-block {
}

.hidden-sm {
    @include breakpoint($mq-sm-min) {
        display: none !important;
    }
}

.m-breadcrumbs-block {
    padding: 0;
    margin-top: 0;
    span {
        margin: 0 1rem;
    }
    li {
        display: inline-block;
    }
    a, li, span {
        font-size: 2rem;
        line-height: 3rem;
        color: $color-text-medium;
        font-family: 'Quicksand', sans-serif;
    }
    @include breakpoint($mq-sm-max){
        display: none;
    }
}

//form
.m-form-block {
    width: 100%;
}

.m-form-file-input-block {
    width: 100%;

    input[type="file"] {
        height: 0;
        overflow: hidden;
        opacity: 0;
        z-index: 1;
        margin: 0;
    }
}

.m-keyword-search-results-block {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 10;

    &.v-hide {
        display: none;
    }
}
