.o-accordion {
    margin-bottom: 5rem;
}

.accordion-title {
    border-top: 1px solid $color-greyscale-5;
    padding-left: 0!important;
    font-family: 'Quicksand', sans-serif;
    cursor: pointer;
    h4 {
        position: relative;
        color: $color-secondary-main;
    }
    .fas {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        transition: 0.4s;
        font-size: 19px;
    }
}

.accordion-title:not(:first-of-type){
    border-top: 0;
}

.accordion-title.active {
    margin-bottom: 0rem;
    .fas {
        // transform: rotate(90deg); 
        transition: 0.4s;
        &:before {
            content: "\f068";
        }
    }
}

.accordion-content {
    max-height: 0;
    overflow: hidden;
    transition: 0.4s;
    border-bottom: 1px solid $color-greyscale-5;
    .text-cont {
        margin-bottom: 2rem;
        img {
            border-radius: 25px;
        }
    }
}