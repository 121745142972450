.m-card {
    border: 1px solid $color-greyscale-5;
    border-radius: 3rem;
    width: 100%;
    margin-bottom: 3rem;
    overflow: hidden;
    .image-cont {
        display: block;
        padding: 4rem;
        position: relative;
        background-color: $color-greyscale-1;
        &:after {
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            height: 1px;
            background-color: $color-greyscale-5;
            bottom: 0;
            left: 0;
        }
    }
    .a-lead-type.v-large {
        line-height: 5rem;
    }
    .text-cont {
        padding: 4rem;
        @include flex(flex-start);
        justify-content: space-between;
        background-color: $color-greyscale-1;
        h4 {
            width: 100%;
            color: $color-secondary-main;
            margin-bottom: 2.75rem;
        }
        .a-label-type {
            width: 100%;
            font-weight: 700;
            margin-bottom: 2.75rem;
        }
    }
}

#search-results-container {
    .m-card {
        min-height: 443px;
        .text-cont {
            min-height: 190px;
        }
    }
}

.v-card-full-image {
    .image-cont {
        padding: 0;
        // img {
        //   border-radius: 25px 25px 0 0;
        // }
    }
}

.v-card-large {
    padding: 3rem 8rem;
    background-image: url("/assets/images/logo-icon-light4.svg");
    background-size: 67%;
    background-repeat: no-repeat;
    background-position: 169% 26%;
    @include breakpoint($mq-xl-max) {
        background-size: 61%;
    }
    @include breakpoint($mq-lg-max) {
        background-image: url("/assets/images/logo-icon-light2.svg");
        background-size: 100%;
    }
    @include breakpoint($mq-sm-max) {
        padding: 0;
    }
    .text-cont {
        max-width: 85rem;
        background-color: transparent;
    }
}
