header.o-header {
    position: relative;
    z-index: 5;
    border-bottom: 1px solid $color-greyscale-2;
    overflow-x: clip; 
   
   
    .show-mobile {
        display: none;
        @include breakpoint($mq-sm-max) {
            display: block;
        }
    }
    @include breakpoint($mq-lg-max) {
        padding: 3rem 0;
    }
    @include breakpoint($mq-sm-max) {
        padding: 2rem 0;
        .show-desktop {
            display: none;
        }
    }
    .mobile-button {
        display: none;
        min-width: auto !important;
        i {
            margin-left: 20px;
        }
        .fa-times-circle {
            display: none;
        }
        .menu {
            display: inline-block;
        }
        .close {
            display: none;
        }
        @include breakpoint($mq-xl-max) {
            display: block;
        }
    }
    .header-container {
        padding: 0 4rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .a-logo-link {
            img {
                max-width: 208px;
                @include breakpoint($mq-sm-max) {
                    max-width: 50px;
                }
            }
        }
        nav {
            max-width: 80%;
            .v-parent {
                position: relative;
            }
            .v-children {
                display: none;
                position: absolute;
                top: 4rem;
                padding-top: 1rem;
                left: 2rem;
                background: $color-greyscale-1;
                width: 30rem;
                animation: fade 0.5s forwards;
            }
            .v-children.active {
                display: block;
            }
            .a-nav-link,
            .v-parent {
                font-family: "Quicksand", sans-serif;
                font-size: 2rem;
                color: transparent;
                margin: 0 2rem;
                display: inline-flex;
                flex-direction: column;
                align-items: center;
                z-index: 10;
                position: relative;
                &:after {
                    position: absolute;
                    content: attr(data-title);
                    color: $color-text-dark;
                    top: 0;
                    width: 200px;
                    height: 1.25rem;
                    left: 0;
                }
                @include breakpoint($mq-xl-max) {
                    display: none;
                }
            }
            .a-nav-link {
                transition: 0.5s;
                &:hover {
                    // text-shadow: 0 0 1px $color-text-dark, 0 0 1px $color-text-dark;
                    &:after {
                        font-weight: bold;
                        transform: translate(-1px);
                    }
                }
            }
            .a-nav-link.v-child {
                display: inline-flex;
                width: 100%;
                align-items: flex-start;
                border: 1px solid $color-greyscale-4;
                border-bottom: 0;
                color: $color-text-dark;
                padding: 0.5rem 2rem;
                position: relative;
                margin: 0;
                transition: 0.2s;
                &:after {
                    display: none;
                }
                &:last-of-type {
                    border-bottom: 1px;
                }
                &:hover {
                    background: $color-greyscale-2;
                    font-weight: bold;
                }
            }
            .a-nav-link.v-active,
            .a-nav-link.is-home.v-active:first-of-type {
                font-weight: bold;
            }
            .a-nav-link.is-not-home.v-active:first-of-type {
                font-weight: bold;
            }
            .mobile-navigation {
                display: none;
                @include breakpoint($mq-xl-max) {
                    position: absolute;
                    padding-top: 1.1rem;
                    height: 100vh;
                    left: 0;
                    background: $color-greyscale-4;
                    width: 100%;
                    z-index: 1;
                    top: 17rem;
                    margin-top: -1px;
                    @include breakpoint($mq-sm-max) {
                        top: 10rem;
                    }
                    .v-nav-mobile-link {
                        display: block;
                        padding: 2rem;
                        color: $color-text-dark;
                    }
                    .v-funding {
                        display: flex;
                        max-width: 200px;
                        margin-top: 2rem;
                    }
                }
            }
        }
    }
    .a-menu-overlay.v-open {
        position: fixed;
        top: 17.5rem;
        left: 0;
        width: 100%;
        height: 100vh;
        background: $color-secondary-main;
        opacity: 90%;
        animation: fade-90 0.5s forwards;
    }
}

header.o-header.active .header-container nav {
    .mobile-navigation {
        display: block;
    }
    .mobile-button {
        background: none;
        border-color: $color-greyscale-1;
        color: $color-greyscale-9;
        .fa-bars {
            display: none;
        }
        .fa-times-circle {
            display: inline-block;
        }
        .menu {
            display: none;
        }
        .close {
            display: inline-block;
        }
    }
}
