.o-form,
.o-form-loading,
.o-form-success,
.o-form-error {
    width: 100%;
}

.o-form-loading {
    width: 100%;
    position: relative;
    min-height: 20rem;
    @include loading-spinner;
}

.o-form {
    .container {
        display: flex;
        flex-wrap: nowrap;
    }
}
