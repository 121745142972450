footer.o-footer {
    .start-xs {
        flex-direction: column;
        align-items: baseline;
    }
    .between-xs {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    h4 {
        width: 100%;
        text-transform: none;
        margin-bottom: 3rem;
        font-size: 3rem;
        line-height: 4rem;
        @include breakpoint($mq-md-max) {
            font-size: 3rem;
            line-height: 4rem;
            margin-top: 3rem;
        }
    }
    .link-item {
        margin-bottom: 3rem;
        font-size: 2rem;
        line-height: 3rem;
        color: $color-text-dark;
        font-family: "Quicksand", sans-serif;
        width: 100%;
        @include breakpoint($mq-md-max) {
            margin-bottom: 2rem !important;
        }
    }

    .v-link-item-functional {
        color: $color-text-functional;
    }
    img {
        width: 100%;
        max-width: 168px;
        @include breakpoint($mq-md-min) {
            max-width: 300px;
        }
    }

    p {
        font-size: 1.5rem;
    }

    .mb-xl {
        margin-bottom: 6rem;
    }

    .fa-facebook-f {
        width: 3rem;
        height: 3rem;
        border-radius: 50%;
        position: relative;
        background-color: $color-greyscale-9;
    }

    .fa-facebook-f:before {
        color: $color-greyscale-1;
        position: absolute;
        top: 64%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
