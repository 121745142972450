.o-section-hero {
    position: relative;
    height: 88rem;
    @include breakpoint($mq-md-max) {
        height: auto;
    }
    h1,
    p {
        color: $color-secondary-main;
        width: 100%;
    }
    p {
        font-size: 4rem;
        line-height: 5rem;
        margin-bottom: 4rem;
    }
    .image-gradient-overlay {
        background: linear-gradient(to left, transparent, white 100%);
        position: absolute;
        width: 58%;
        height: 100%;
        top: 0;
        right: 0;
        .image-overlay {
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            width: 100%;
            background-size: cover;
            z-index: -1;
            @include breakpoint($mq-md-max) {
                position: static;
                display: block;
            }
        }
        @include breakpoint($mq-md-max) {
            background: none;
            width: 100%;
            height: 556px;
            display: block;
            position: static;
        }
        @include breakpoint($mq-sm-max) {
            height: 376px;
        }
    }
    .text-cont {
        width: 75rem;
        max-width: 100%;
        height: 100%;
        @include flex(center);
        align-items: flex-start;
    }
}

.o-section-intro {
    .text-cont {
        margin: auto;
        max-width: 105rem;
        @include flex(center);
        text-align: center;
        .a-button {
            margin-top: 4rem;
        }

        h2,
        p,
        div {
            width: 100%;
        }
    }
}


.o-full-width-video-section {
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 75rem;
    max-height: 75rem;
    overflow: hidden;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
 
    .video-wrapper{
        display: flex;
        width: 100%;
        margin: 0;
        position: relative;
        overflow: hidden;
       

    } 

    iframe { 
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); 
        width: 100vw;
        height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
        min-height: 100vh;
        min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
        background-color: white;
    }


    .controls 
    {   position: absolute; 
        top:6rem; 
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        width: 100%;
        max-width: 153rem;
        margin: auto;
        display: flex;
        justify-content: flex-end;


        button {
            position: relative;
            color: white;
            background-color: transparent;
            border: 2px solid white;
            border-radius: 50%;
            width: 40px;
            height: 40px;
            &.js-play-pause {
                .fa-play:before {
                    content: "\f04b";
                }
                &.playing {
                    .fa-play:before {
                        content: "\f04c";
                    }
                }
            }
            &.js-sound {
                right: 10.5rem;
                .fa-volume:before {
                    content: "\f6a9";
                }
                &.muted {
                    .fa-volume:before {
                        content: "\f6a8";
                    }
                }
            }
        }
    }  

    @include breakpoint($mq-xl-max) {
        .controls {
            button {
                margin-right: 40px;
            }
        }
        
    }  

    @include breakpoint($mq-md-max) {
      
       min-height: inherit; 

        .video-wrapper{
            overflow: hidden;
            padding-top: 56.25%; /* 16:9 */
            position: relative;
        } 


        iframe {
            position: absolute;
            top: 0;
            left: 0;
            border: 0;
            right:0;
            transform: translate(0%, 0%); 
            width: 100%;
            height: 100%;
            min-width: inherit; 
            min-height: inherit;

        }
        
    } 

  
}

.o-fifty-fifty-section {
    display: flex;
    height: 840px;
    background-image: url(/assets/images/logo-icon-light.svg);
    background-size: contain;
    background-position: -65%;
    background-repeat: no-repeat;
    h2 {
        margin-bottom: 3rem;
    }
    .text-cont {
        padding: 3rem 14.5rem 3rem 3rem;
        @include flex(flex-start);
        max-width: 80rem;
        margin-left: auto;

        h2,
        p {
            width: 100%;
        }

        @include breakpoint($mq-lg-max) {
            padding: 3rem !important;
            max-width: 30rem !important;
            margin: auto !important;
        }
        @include breakpoint($mq-md-max) {
            max-width: inherit !important;
        }
    }
    .image-cont {
        @include breakpoint($mq-lg-max) {
            background-size: cover;
            h2 {
                font-size: 5rem;
                line-height: 4rem;
            }
        }
    }
    @include breakpoint($mq-lg-max) {
        height: 418px;
        p {
            display: none;
        }
    }
    @include breakpoint($mq-sm-max) {
        height: auto;
        background-image: url(/assets/images/logo-icon-light.svg);
        flex-direction: column-reverse;
        background-color: $color-greyscale-1;
        background-size: 500px;
        background-position: center bottom -300px;
        .w-50 {
            width: 100%;
        }
        .image-cont {
            margin: 3rem;
            width: calc(100% - 6rem);
            background-size: cover;
            min-height: 400px;
        }
        p {
            display: block;
        }
    }
    &.v-video {
        .image-cont {
            display: flex;
            align-items: center;
            justify-content: center;
            .fal {
                font-size: 6rem;
                cursor: pointer;
            }
            .fa-play-circle:before {
                color: white;
            }
        }
    }
}

.o-fifty-fifty-section.mw-m {
    max-width: 210rem;
}

.o-fifty-fifty-section:nth-of-type(odd) {
    flex-direction: row-reverse;
    background-position: 159%;
    .text-cont {
        padding: 3rem 3rem 3rem 14.5rem;
        margin-right: auto;
        margin-left: inherit;
        @include breakpoint($mq-sm-max) {
            padding: 3rem 3rem 3rem 3rem;
        }
    }
    @include breakpoint($mq-sm-max) {
        background-image: url(/assets/images/logo-icon-light3.svg);
        flex-direction: column-reverse;
        background-color: $color-greyscale-2;
        background-position: center bottom -300px;
    }
}

.o-section-banner {
    height: 408px;
    background-size: cover;
    position: relative;
    .a-gradient-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $color-primary-80-tint;
        display: none;
    }
    .text-cont {
        height: 100%;
        @include flex(baseline);
    }
    h1 {
        padding: 3rem;
        background-color: $color-primary-80-tint;
        display: inline-block;
    }
    @include breakpoint($mq-md-max) {
        height: 26rem;
    }
    @include breakpoint($mq-sm-max) {
        height: 21rem;
        h1 {
            background-color: transparent;
        }
        .a-gradient-overlay {
            display: block;
        }
    }
}

.o-section-text {
    h4,
    h4 span {
        color: $color-secondary-main;
        margin: 1rem 0rem 2rem 0rem;
        font-size: 4rem !important;
        line-height: 5rem !important;
        font-family: "Quicksand", sans-serif;
    }
    h4:not(:first-of-type) {
        margin: 4rem 0rem 2rem 0rem;
    }
    a[rel~="noopener"] {
        display: inline-block;
    }

    p {
        a {
            color: $color-primary-light;
            &:hover {
                text-decoration: underline;
            }
        }
    }
}

.o-section-image {
    display: inline-block;
    margin-bottom: 5rem;
    position: relative;
    width: 100%;
    img {
        border-radius: 25px;
        width: 100%;
    }
    .image-slider-cont {
        height: 500px;
        .lSSlideOuter,
        #image-slider,
        .slide {
            height: 100%;
        }
        .slide {
            border-radius: 25px;
            background-size: cover;
            background-position: center;
            // img {
            //     display: none;
            // }
        }
        .lSSlideWrapper {
            height: 84%;
        }
        .lSGallery {
            margin-top: 1rem !important;
            margin-top: 68px;
            img {
                border-radius: 0;
            }
        }
        .lSAction > .lSPrev,
        .lSAction > .lSNext {
            &::before {
                content: "\f053";
                font-family: "Font Awesome 5 Pro";
                font-weight: 400;
                -webkit-font-smoothing: antialiased;
                display: inline-block;
                font-style: normal;
                font-variant: normal;
                text-rendering: auto;
                line-height: 1;
                color: white;
                font-size: 4rem;
            }
        }
        .lSAction > .lSNext {
            &::before {
                content: "\f054";
            }
        }
    }
}

.o-section-video {
    display: inline-block;
    margin-bottom: 5rem;
    position: relative;
    width: 100%;
    i {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-55%, -62%);
        font-size: 64px;
        color: white;
        cursor: pointer;
    }
    img {
        border-radius: 25px;
        cursor: pointer;
        width: 100%;
    }
    .player .vp-controls {
        display: none;
        margin-top: 1px;
    }
    label {
        width: 100%;
        display: block;
        text-align: right;
        font-size: 2rem;
        margin-top: 1rem;
        font-family: "Quicksand", sans-serif;
    }
}

.o-section-video-modal {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000000;
    cursor: pointer;
    opacity: 0;
    display: none;
    .fas {
        color: white;
        text-align: right;
        width: 100%;
    }
    .modal-content {
        position: relative;
        max-width: 900px;
        margin: auto;
        top: 50%;
        transform: translateY(-50%);
    }
}

.o-section-video-modal.active {
    opacity: 1;
    display: block;
}

.o-section-timeline {
    overflow: hidden;
    .m-timeline-item {
        margin: 0 0 0 auto;
        position: relative;
        padding-left: 9.5%;
        padding-bottom: 5rem;
        p {
            margin-bottom: 0;
            margin-top: 2rem;
            br {
                display: none;
            }
        }
        ul {
            margin-top: 1.5rem;
            padding-left: 3rem;
            li:not(:first-child) {
                padding-top: 1rem;
            }
        }
        h3 {
            color: $color-secondary-main;
            margin-top: 1rem;
        }
        img {
            height: 330px;
            width: 496px;
            border-radius: 25px;
        }
        .a-year {
            position: absolute;
            top: 50%;
            left: -60%;
            transform: translateY(-50%);
            font-size: 125px;
            color: $color-tertiary-main;
        }
        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 4px;
            background-color: $color-tertiary-main;
        }
        &:after {
            content: "";
            opacity: 0;
            position: absolute;
            top: 50%;
            left: -10px;
            transform: translateY(-50%);
            height: 24px;
            width: 24px;
            border-radius: 50%;
            background-color: $color-tertiary-main;
        }
        .timeline-container,
        .year-cont {
            transition: 0.5s;
            opacity: 0;
        }
        .timeline-container {
            transform: translateX(100%);
        }
        .year-cont {
            transform: translateX(-100%);
        }
        @include breakpoint($mq-lg-max) {
            width: 75%;
            img {
                width: 416px;
                height: 278px;
            }
            .a-year {
                font-size: 64px;
                top: 0;
                transform: none;
                left: -170px;
            }
        }
        @include breakpoint($mq-sm-max) {
            width: 100%;
            padding: 152px 0 0 0;
            img {
                width: 336px;
                height: 224px;
            }
            .a-year {
                font-size: 64px;
                top: 72px;
                transform: none;
                left: 50%;
                transform: translateX(-50%);
            }
            &:before {
                height: 32px;
                top: 0;
                left: 50%;
            }
            &:after {
                display: none;
            }
        }
    }
    .m-timeline-item:nth-of-type(even) {
    }
    @include breakpoint($mq-lg-min) {
        .m-timeline-item:nth-of-type(even) {
            margin: 0 auto 0 0;
            padding-right: 9.5%;
            padding-left: 0;
            .a-year {
                top: 50%;
                right: -60%;
                left: inherit;
                transform: translateY(-50%);
            }
            &:before {
                content: "";
                top: 0;
                right: -4px;
                left: inherit;
            }
            &:after {
                content: "";
                top: 50%;
                right: -14px;
                left: inherit;
                transform: translateY(-50%);
            }
            .timeline-container {
                transform: translateX(-100%);
            }
            .year-cont {
                transform: translateX(100%);
            }
        }
    }
}

.m-timeline-item:not(:first-of-type) {
    @include breakpoint($mq-sm-min) {
        padding-top: 5rem;
    }
}

.m-timeline-item:nth-of-type(even).v-slide-in,
.m-timeline-item.v-slide-in {
    display: block;
    &:after {
        opacity: 1;
    }
    .timeline-container {
        opacity: 1;
        transform: translateX(0%);
    }
    .year-cont {
        opacity: 1;
        transform: translateX(0%);
    }
}

.o-contact-section {
    .m-form {
        font-family: "Quicksand", sans-serif;
        font-size: 2rem;
        p {
            font-size: 2rem;
            line-height: 3rem;
            padding-left: 1rem;
            padding-bottom: 2rem;
            color: $color-greyscale-9;
            margin-top: -1rem;
        }
    }
    label {
        font-size: 2rem;
        color: $color-greyscale-9;
    }
    .m-label-input {
        padding-bottom: 3rem;
    }
}

// class won't work from layout.scss
.ta-right {
    text-align: right;
}
