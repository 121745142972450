//Font variables
//Import rules must has ".css" at the end of the URL
@import url("https://fonts.googleapis.com/css2?family=EB+Garamond:wght@600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500;700&display=swap");

// Text tags
body {
    @include font-primary-family();
    @include font();
    // normailise fonts
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.a-h1,
h1 {
    @include font(8rem, 9rem);
    font-weight: 700;
    margin-bottom: $space-m;

    @include breakpoint($mq-md-max) {
        @include font(7rem, 8rem);
    }

    @include breakpoint($mq-sm-max) {
        @include font(6rem, 7rem);
    }
}

.a-h1-display {
    @include font(10rem, 11rem);
    font-weight: 700;
    margin-bottom: $space-m;

    @include breakpoint($mq-md-max) {
        @include font(8rem, 9rem);
    }

    @include breakpoint($mq-sm-max) {
        @include font(7rem, 8rem);
    }
}

.a-h2,
h2 {
    @include font(7rem, 8rem);
    font-weight: 700;
    margin-bottom: $space-m;

    @include breakpoint($mq-md-max) {
        @include font(6rem, 7rem);
    }

    @include breakpoint($mq-sm-max) {
        @include font(5rem, 6rem);
    }
}

.a-h3,
h3 {
    @include font(5.5rem, 6.5rem);
    font-weight: 700;
    margin-bottom: $space-m;

    @include breakpoint($mq-md-max) {
        @include font(5rem, 6rem);
    }

    @include breakpoint($mq-sm-max) {
        @include font(4.5rem, 5rem);
    }
}

.a-h4,
h4 {
    @include font(4rem, 6rem);
    font-weight: 700;
    margin-bottom: $space-m;
    text-transform: uppercase;

    @include breakpoint($mq-md-max) {
        @include font(2.5rem, 3rem);
    }

    @include breakpoint($mq-sm-max) {
        @include font(2.25rem, 3rem);
    }
}

.a-lead-type {
    @include font(3rem, 4rem);
    font-weight: $default-font-weight;
    margin-bottom: $space-s;
    text-transform: none;
    @include breakpoint($mq-md-max) {
        @include font(2.5rem, 3rem);
    }

    @include breakpoint($mq-sm-max) {
        @include font(2.25rem, 3rem);
    }
}

.a-lead-type.v-large {
    @include font(4rem, 3rem);
    font-weight: 700;
    margin-bottom: $space-s;

    @include breakpoint($mq-md-max) {
        @include font(3.5rem, 4rem);
    }

    @include breakpoint($mq-sm-max) {
        @include font(3.25rem, 3rem);
    }
}

p {
    @include font();
    font-weight: $default-font-weight;
    margin-bottom: $space-s;
}

b,
strong,
.v-bold {
    font-weight: 700;
}

i,
em,
.v-italic {
    font-style: italic;
}

sup,
sub {
    font-size: 75%;
}

a {
    color: $color-primary-light;
    text-decoration: none;

    &:hover,
    &:active,
    &.v-active {
        color: $color-primary-light;
    }
}

ul,
ol {
    margin-bottom: 1rem;
}

label,
.a-label-type {
    @include font(2rem, 2rem);
    margin-bottom: 1rem;
    font-family: "Quicksand", sans-serif;
    &.a-error-type {
        display: none;
        width: 100%;
        color: $color-warning-medium;
        margin-bottom: 2rem;
    }
}

.a-attribution-type {
    @include font(1rem, 2rem);
}

.a-quote-type {
    @include font(3rem, 4rem);
}

.a-quotation-mark-type {
    @include font(3rem, 3rem);
}
