@function tint($color, $percentage) {
   @return mix(white, $color, $percentage);
 }

/**
 * Colors
 */
$color-greyscale-1: #ffffff; /* Greyscale 1*/
$color-greyscale-2: #F2F1ED; /* Greyscale 2*/
$color-greyscale-3: #E8E7E3; /* Greyscale 3*/
$color-greyscale-4: #DEDDD9; /* Greyscale 4*/
$color-greyscale-5: #BDBDBD; /* Greyscale 5*/
$color-greyscale-6: #9E9E9E; /* Greyscale 6*/
$color-greyscale-7: #757575; /* Greyscale 7*/
$color-greyscale-8: #616161; /* Greyscale 8*/
$color-greyscale-9: #161714; /* Greyscale 9*/

$color-primary-dark: #47baee; /* Primary Dark Color */
$color-primary-light: #97dfff; /* Primary Light Color */
$color-primary-main: rgba(78,163,193,1); /* Primary Main Color */
$color-primary-80-tint : rgba(78,163,193,0.8);
$color-primary-40-tint :rgba(78,163,193,0.4);
$color-primary-10-tint : rgba(78,163,193,0.1);

$color-secondary-dark: #ee8347; /* Secondary Dark Color */
$color-secondary-light: #ffe297; /* Secondary Light Color */
$color-secondary-main: #2C596A; /* Secondary Main Color */
$color-secondary-80-tint : rgba(78,163,193,0.8);
$color-secondary-40-tint : tint($color-secondary-main, 40%);
$color-secondary-10-tint : tint($color-secondary-main, 10%);

$color-tertiary-main: #B9DBE5; /* Secondary Main Color */
$color-tertiary-80-tint : tint($color-tertiary-main, 80%);
$color-tertiary-40-tint : tint($color-tertiary-main, 40%);
$color-tertiary-10-tint : tint($color-tertiary-main, 10%);

$color-success-light: #99ff97; /* Success Light Color */
$color-success-medium: #868F4F; /* Success Medium Color */
$color-success-dark: #868F4F; /* Success Dark Color */

$color-warning-light: #ff9797; /* Warning Light Color */
$color-warning-medium: #FDAA29; /* Warning Medium Color */
$color-warning-dark: #FDAA29; /* Warning Dark Color */

$color-text-light: $color-greyscale-1; /* Text Light Color */
$color-text-medium: #6B6C7A; /* Text Medium Color */
$color-text-dark: $color-greyscale-9; /* Text Dark Color */

$color-background-light: $color-greyscale-1; /* Background Light Color */
$color-background-medium: $color-greyscale-2; /* Background Medium Color */
$color-background-dark: $color-greyscale-9; /* Background Dark Color */

$color-border-light: $color-greyscale-3; /* Border Light Color */
$color-border-medium: $color-greyscale-5; /* Border Medium Color */
$color-border-dark: $color-greyscale-7; /* Border Dark Color */

$color-text-functional: #114AF3;

/**
   * Sizes
   */
$default-rem-base-size: 8px;
$default-font-size: 3rem;
$default-line-height: 4rem;
$default-font-weight: 400;
$default-border-size: 0.125rem;

/**
   * Spacing
   */
$space-xxs: 1rem;
$space-xs: 2rem;
$space-s: 3rem;
$space-m: 4rem;
$space-l: 6rem;
$space-xl: 6rem;
$space-xxl: 10rem;

$default-text-margin-bottom: $space-xxs;
$default-gutter-space: $space-s;

/*
   * Border
  */
$default-border-color: $color-border-medium;
$border-xxs-value: $default-border-size solid $default-border-color;

/**
    * Shadows
    */
$shadow-2: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2);
$shadow-6: 0 0.375rem 0.75rem rgba(0, 0, 0, 0.2);
$shadow-12: 0 0.75rem 1.5rem rgba(0, 0, 0, 0.2);
$shadow-24: 0 1.5rem 3rem rgba(0, 0, 0, 0.2);

/**
   * Animation
   */
$default-animation-timing-function: #{"ease"};
$default-animation-duration: 0.4s;

/**
   * Responsive
   */

/* This will need to include the padding from .container, e.g.
   if 1240px is the site width, add the left/right padding (24px + 24px) = 
   1288px = 161rem */
$site-container-width: 161rem;

$mq-xl-max: max-width 79.99em; /* Desktop First MQ - 1280px */
$mq-lg-max: max-width 63.99em; /* Desktop First MQ - 1024px */
$mq-md-max: max-width 53.124em; /* Desktop First MQ - 768px */
$mq-sm-max: max-width 37.49em; /* Desktop First MQ - 600px */
$mq-xm-max: max-width 30.99em; /* Desktop First MQ - 496px */

$mq-xl-min: min-width 80em; /* Mobile First MQ - 1280px */
$mq-lg-min: min-width 64em; /* Mobile First MQ - 1024px */
$mq-md-min: min-width 53.125em; /* Mobile First MQ - 768px */
$mq-sm-min: min-width 37.5em; /* Mobile First MQ - 600px */
$mq-xm-min: min-width 31em; /* Mobile First MQ - 496px */

/**
   * Images
   */
$img-loading: "/assets/images/loading.gif";
$img-select-arrow: "/assets/images/select-dropdown.svg";
