﻿.color-greyscale-1 {
    color: $color-greyscale-1;
}

.color-greyscale-2 {
    color: $color-greyscale-2;
}

.color-greyscale-3 {
    color: $color-greyscale-3;
}

.color-greyscale-4 {
    color: $color-greyscale-4;
}

.color-greyscale-5 {
    color: $color-greyscale-5;
}

.color-greyscale-6 {
    color: $color-greyscale-6;
}

.color-greyscale-7 {
    color: $color-greyscale-7;
}

.color-greyscale-8 {
    color: $color-greyscale-8;
}

.color-greyscale-9 {
    color: $color-greyscale-9;
}

// Primary
.color-primary-light {
    color: $color-primary-light;
}

.color-primary-main {
    color: $color-primary-main;
}

.color-primary-dark {
    color: $color-primary-dark;
}

// Secondary
.color-secondary-light {
    color: $color-secondary-light;
}

.color-secondary-main {
    color: $color-secondary-main;
}

.color-secondary-dark {
    color: $color-secondary-dark;
}

// Success
.color-success-light {
    color: $color-success-light;
}

.color-success-medium {
    color: $color-success-medium;
}

.color-success-dark {
    color: $color-success-dark;
}

// Warning
.color-warning-light {
    color: $color-warning-light;
}

.color-warning-medium {
    color: $color-warning-medium;
}

.color-warning-dark {
    color: $color-warning-dark;
}

// Text
.color-text-light {
    color: $color-text-light;
    span {
        color: $color-text-light;
    }
}

.color-text-medium {
    color: $color-text-medium;
}

.color-text-dark {
    color: $color-text-dark;
}

//Background
.bg-color-background-light {
    background-color: $color-background-light;
}

.bg-color-background-medium {
    background-color: $color-background-medium;
}

.bg-color-background-dark {
    background-color: $color-background-dark;
}

//Background Greyscale
.bg-color-greyscale-1 {
    background-color: $color-greyscale-1;
}

.bg-color-greyscale-2 {
    background-color: $color-greyscale-2;
}

.bg-color-greyscale-3 {
    background-color: $color-greyscale-3;
}

.bg-color-greyscale-4 {
    background-color: $color-greyscale-4;
}

.bg-color-greyscale-5 {
    background-color: $color-greyscale-5;
}

.bg-color-greyscale-6 {
    background-color: $color-greyscale-6;
}

.bg-color-greyscale-7 {
    background-color: $color-greyscale-7;
}

.bg-color-greyscale-8 {
    background-color: $color-greyscale-8;
}

.bg-color-greyscale-9 {
    background-color: $color-greyscale-9;
}

//Background Primary
.bg-color-primary-light {
    background-color: $color-primary-light;
}

.bg-color-primary-main-10 {
    background-color: #edf6f9;
}

.bg-color-primary-main {
    background-color: $color-primary-main;
}

.bg-color-primary-dark {
    background-color: $color-primary-dark;
}
 
//Background Secondary
.bg-color-secondary-light {
    background-color: $color-secondary-light;
}

.bg-color-secondary-main {
    background-color: $color-secondary-main;
}

.bg-color-secondary-dark {
    background-color: $color-secondary-dark;
}

//Background Success
.bg-color-success-light {
    background-color: $color-success-light;
}

.bg-color-success-medium {
    background-color: $color-success-medium;
}

.bg-color-success-dark {
    background-color: $color-success-dark;
}

//Background Warning
.bg-color-warning-light {
    background-color: $color-warning-light;
}

.bg-color-warning-medium {
    background-color: $color-warning-medium;
}

.bg-color-warning-dark {
    background-color: $color-warning-dark;
}

//Border
.border-color-light {
    border-color: $color-border-light;
}

.border-color-medium {
    border-color: $color-border-medium;
}

.border-color-dark {
    border-color: $color-border-dark;
}
