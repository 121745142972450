.m-paging-container {
  width: 100%;
  margin-top: 3rem;
  a {    
    font-size: 2rem;
    color: $color-greyscale-5;
  }
  a.active {
    background: $color-primary-main;
    padding: 0 1rem;
    color: $color-greyscale-1;
    margin: 0 1.5rem;
  }
}